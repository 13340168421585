<template>
    <front-layout>
        <div class="flex-wrapper-full-background">
            <div class="intro mx-auto">
                <v-container>
                    <h1 class="text-center mb-11" v-text="'File Conversion API'"/>
                    <p class="text-center">
                        We designed our API to be developer-friendly with great documentation,
                        clear code samples and a support team stuffed entirely by developers.
                    </p>
                    <v-row justify="center">
                        <v-col cols="8">
                            <v-card >
                                <v-card-title v-text="'Your personal API Key'"/>
                                <template v-if="!$auth.check()">
                                    <v-card-text>
                                        Register or Login for free to get an API Key
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            :to="{name: 'login'}"
                                            small
                                            elevation="0"
                                            color="primary"
                                            v-text="'Log in'"
                                            class="ml-2"/>
                                    </v-card-actions>
                                </template>
                                <template v-else>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="8">
                                                <v-text-field v-model="token" label="Token" disabled/>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn
                                                    elevation="0"
                                                    block
                                                    @click.prevent="generateToken"
                                                    v-text="'Generate Api Key'"/>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </template>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="4">
                            <v-card>
                                <v-card-title v-text="'API Pricing'"/>
                                <v-card-text>
                                    Change price-plan in order to use API
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        :to="{name: 'api.pricing'}"
                                        small
                                        elevation="0"
                                        color="primary"
                                        v-text="'Api pricing'"
                                        class="ml-2"/>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card>
                                <v-card-title v-text="'Documentation'"/>
                                <v-card-text v-text="' This docs cover how to use the API.'"/>
                                <v-card-actions>
                                    <v-btn
                                        href="/api/documentation"
                                        small
                                        elevation="0"
                                        color="primary"
                                        v-text="'Api documentations'"
                                        class="ml-2"/>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </front-layout>
</template>

<script>
import FrontLayout from "../../layouts/FrontLayout";
import Api from "../../api/Api";

export default {
    components: {
        FrontLayout
    },
    data() {
        return {
            token: null
        }
    },
    methods: {
        async generateToken() {
            this.token = await Api.generateToken()
        }
    }
}
</script>
